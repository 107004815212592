<template>
  <div class="mt-5 py-5 bg-white">
    <v-flex display-flex flex-column mb-3 align-center justify-center>
      <h3 class="title-container">Nossos agradecimentos</h3>
    </v-flex>
	<div class="container wrapper-scroll-father wrapper-container p-relative">
		<a href="javascript:;" onclick="document.getElementById('usThanks').scrollBy(370, 0)" class="scroll-arrow next">
			<i class="far fa-chevron-right" style="font-size: 16px;"></i>
		</a>
		<a href="javascript:;" onclick="document.getElementById('usThanks').scrollBy(-370, 0)" class="scroll-arrow prev">
			<i class="far fa-chevron-left" style="font-size: 16px;"></i>
		</a>
		<v-layout row wrapper-scroll mx-0 id="usThanks">
			<v-flex box-thanks shrink>
				<h3>até R$300</h3>
				<p>Far far away, behind the word mountains, far from the countries</p>
				<button color="white" class="btn-default-round-blue">Quero doar</button>
			</v-flex>
			<v-flex box-thanks shrink>
				<h3>até R$300</h3>
				<p>Far far away, behind the word mountains, far from the countries</p>
				<button color="white" class="btn-default-round-blue">Quero doar</button>
			</v-flex>
			<v-flex box-thanks shrink>
				<h3>até R$300</h3>
				<p>Far far away, behind the word mountains, far from the countries</p>
				<button color="white" class="btn-default-round-blue">Quero doar</button>
			</v-flex>
			<v-flex box-thanks shrink>
				<h3>até R$300</h3>
				<p>Far far away, behind the word mountains, far from the countries</p>
				<button color="white" class="btn-default-round-blue">Quero doar</button>
			</v-flex>
			<v-flex box-thanks shrink>
				<h3>até R$300</h3>
				<p>Far far away, behind the word mountains, far from the countries</p>
				<button color="white" class="btn-default-round-blue">Quero doar</button>
			</v-flex>
			<v-flex box-thanks shrink>
				<h3>até R$300</h3>
				<p>Far far away, behind the word mountains, far from the countries</p>
				<button color="white" class="btn-default-round-blue">Quero doar</button>
			</v-flex>
		</v-layout>
	</div>
  </div>
</template>
<script type="plain/text">
export default {
  data() {
    return {
      
    };
  }
};
</script>