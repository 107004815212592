<template>
  <v-layout row wrap container-fluid aux-cfluid w-100 px-4 my-5 pb-5>
    <v-flex xs12>
      <h3
        class="color-theme-texto font-destaque f-size-32 title-container container display-flex justify-center c-gray-dark mb-4"
      >Cronograma de Atividades</h3>
    </v-flex>
    <v-layout row wrap-activity-cronogram>
      <v-flex sm4 bg-white style="border-radius:6px;box-shadow: -2px 5px 6px 0 rgba(214,217,231,0.29), 0 9px 21px -4px rgba(173,182,217,0.17)">
        <div class="list-activity-cronogram">
          <template v-for="schedule in scheduleList">
            <div
              :key="schedule.Order"
              class="list-activity-box justify-start"
              :class="scheduleSelected.Order == schedule.Order ? 'active' : ''"
              @click="scheduleSelected = schedule"
            >
              <img :src="schedule.file.path" alt />
              <div>
                <span>{{getEventDay(schedule)}}</span>
                <h2>{{schedule.name}}</h2>
                <p>{{schedule.description}}</p>
              </div>
            </div>
          </template>
        </div>
      </v-flex>
      <v-flex sm8>
        <div class="box-content-activity">
          <img :src="scheduleSelected.file.path" alt />
          <div class="list-activity-box">
            <div class="my-4">
              <a @click="previous()">
                <Icon name="fal fa-chevron-left" size="24" class="mr-5" colorFont="#80849A" />
              </a>
              <a @click="next()">
                <Icon name="fal fa-chevron-right" size="24" colorFont="#80849A" />
              </a>
            </div>
            <span class="color-theme-destaque">{{getPeriodDate()}}</span>
            <h2 class="my-2 color-theme-primaria">{{scheduleSelected.name}}</h2>
            <p class="color-theme-texto">{{scheduleSelected.description}}</p>
            <p class="color-theme-texto">{{scheduleSelected.description.schedulePeriodList}}</p>
            <h4 class="color-theme-texto">Adicione este evento no seu calendário</h4>
            <div>
              <a :href="createGoogleCalendarLink" class="text-info" target="_blank">
                  <v-icon size="14" class="text-info mr-2 v-middle-adjust">fal fa-calendar</v-icon>
                  Google Agenda</a>
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-layout>
  <!-- <div class="mt-5">
    <div>
      <h3
        class="title-container container display-flex justify-center c-gray-dark"
      >Cronograma de Atividades</h3>
    </div>
    <div class="cronograma">
      <div class="container-fluid aux-cfluid w-100 px-4">
        <v-layout row wrap activity-lproject>
          <v-flex md4 display-flex flex-column pr-3>
            <div class="card-container fl1">
              <div class="display-flex flex-column h100">
                <div class="display-flex h100">
                  <div class="display-flex flex-column picker-project fl1">
                    <v-date-picker
                      v-model="picker"
                      :events="scheduleArray"
                      :event-color="pinColor"
                      @change="changeScheduleDate"
                      locale="pt-BR"
                    ></v-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
          <v-flex md8>
            <div class="card-container atividades">
              <v-layout wrap row h100>
                <v-flex md5 sm5>
                  <div
                    v-if="scheduleSelected.file"
                    class="image"
                    :style="'background-image:url(' + scheduleSelected.file.imageContent + ')'"
                  ></div>
                </v-flex>
                <v-flex md7 sm7 pa-3>
                  <template v-for="period in scheduleSelected.schedulePeriodList">
                    <h6
                      class="my-3 font-weight-bold title-red justify-start display-flex"
                      :key="period.id"
                    >{{ period.finalDate ? formatterHelper.formatDate(period.initialDate) + ' - ' + formatterHelper.formatDate(period.finalDate) : formatterHelper.formatDate(period.initialDate) }}</h6>
                  </template>
                  <h3 class="mb-4 mt-0">{{scheduleSelected.name}}</h3>
                  <p class="text-xs-left">{{scheduleSelected.description}}</p>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </div>-->
</template>
<script type="plain/text">
import FileImage from "@/components/FileImage.vue";
import Schedule from "@/scripts/models/schedule.model";
import FileService from "@/scripts/services/file.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
export default {
  components: {
    FileImage
  },
  props: ["activities", "pinColor"],
  data() {
    return {
      fileService: new FileService(),
      formatterHelper: new FormatterHelper(),
      scheduleSelected: Object,
      scheduleList: []
      // scheduleArray: [],
      // simplifyScheduleArray: [],
      // picker: new Date().toISOString().substr(0, 10)
    };
  },
  created() {
    this.buildScheduleList();
    this.scheduleSelected = this.scheduleList[0];
    // this.getScheduleImage();
  },
  computed: {
    createGoogleCalendarLink: {
      get() {
        if (this.scheduleSelected) {
          var dates = "20201227/20201230";
          return (
            "http://www.google.com/calendar/event?action=TEMPLATE&text=" +
            this.scheduleSelected.name +
            "&dates=" +
            this.formatDatesToCalendar() +
            "&details=" +
            this.scheduleSelected.description
          );
        }
        return "";
      },
      set(val) {
        this.valid = !val;
      }
    }
  },
  // mounted() {
  //   if (this.scheduleList.length > 0) {
  //     let dates = [];
  //     for (let i = 0; i < this.scheduleList.length; i++) {
  //       let currentSchedule = this.scheduleList[i];
  //       for (let j = 0; j < currentSchedule.schedulePeriodList.length; j++) {
  //         if (currentSchedule.schedulePeriodList[j].finalDate) {
  //           dates = CommonHelper.getDatesBetweenTwo(
  //             currentSchedule.schedulePeriodList[j].initialDate,
  //             currentSchedule.schedulePeriodList[j].finalDate
  //           );
  //           this.scheduleArray = this.scheduleArray.concat(dates);
  //         } else {
  //           dates = this.formatterHelper.formatDateWithPattern(
  //             currentSchedule.schedulePeriodList[j].initialDate,
  //             "YYYY-MM-DD"
  //           );
  //           this.scheduleArray.push(dates);
  //         }
  //         this.simplifyScheduleArray.push({
  //           id: currentSchedule.id,
  //           dates: dates
  //         });

  //         //Check if today has a event
  //         var today = new Date().toISOString().substr(0, 10);
  //         this.changeScheduleDate(today);
  //       }
  //     }
  //   }
  // },
  methods: {
    buildScheduleList() {
      var count = 0;
      for (let i = 0; i < this.activities.length; i++) {
        for (let j = 0; j < this.activities[i].schedulePeriodList.length; j++) {
          var current = new Schedule();
          current.Order = count++;
          current.id = this.activities[i].id;
          current.name = this.activities[i].name;
          current.description = this.activities[i].description;
          current.file = this.activities[i].file;
          current.blocked = this.activities[i].blocked;
          current.projectId = this.activities[i].projectId;
          current.schedulePeriod = this.activities[i].schedulePeriodList[j];
          this.scheduleList.push(current);
        }
      }
    },
    next() {
      var next =
        this.scheduleList.findIndex(x => x.Order == this.scheduleSelected.Order) + 1;
      if (next > this.scheduleList.length - 1) {
        next = 0;
      }
      this.scheduleSelected = this.scheduleList[next];
    },
    previous() {
      var previous =
        this.scheduleList.findIndex(x => x.Order == this.scheduleSelected.Order) - 1;
      if (previous < 0) {
        previous = this.scheduleList.length - 1;
      }
      this.scheduleSelected = this.scheduleList[previous];
    },
    getEventDay(selected) {
      return this.formatDateToPTBR(selected.schedulePeriod.initialDate);
    },
    getPeriodDate() {
      var result = this.formatDateToPTBR(
        this.scheduleSelected.schedulePeriod.initialDate
      );
      if (this.scheduleSelected.schedulePeriod.finalDate) {
        result +=
          " - " +
          this.formatDateToPTBR(this.scheduleSelected.schedulePeriod.finalDate);
      }

      return result;
    },
    formatDateToPTBR(date) {
      return this.$moment(date).format("LL");
    },
    formatDatesToCalendar() {
      var result = this.formatterHelper.formatDateWithPattern(
        this.scheduleSelected.schedulePeriod.initialDate,
        "YYYYMMDD"
      );

      var nextDate = this.scheduleSelected.schedulePeriod.initialDate;
      if (this.scheduleSelected.schedulePeriod.finalDate) {
        nextDate = this.scheduleSelected.schedulePeriod.finalDate;
      }

      var date = this.$moment(nextDate).add(1, "days");
      result +=
        "/" + this.formatterHelper.formatDateWithPattern(date, "YYYYMMDD");

      return result;
    }
    // changeScheduleDate(date) {
    //   if (this.scheduleArray.includes(date)) {
    //     let selected = this.simplifyScheduleArray.filter(
    //       function(item) {
    //         return item.dates.includes(date);
    //       }.bind(this)
    //     );
    //     if (selected.length >= 1) {
    //       this.scheduleSelected = this.scheduleList.find(
    //         item => item.id == selected[0].id
    //       );
    //       this.getScheduleImage();
    //     }
    //   } else {
    //     this.scheduleSelected = new Schedule();
    //     this.scheduleSelected.description =
    //       "Não existe atividade programada para a data selecionada.";
    //   }
    // },
    // getScheduleImage() {
    //   if (this.scheduleSelected.file) {
    //     if (this.scheduleSelected.file.hasOwnProperty("imageContent")) {
    //       return this.scheduleSelected.file.imageContent;
    //     } else {
    //       this.fileService
    //         .render(this.scheduleSelected.file.id)
    //         .then(data =>
    //           this.$set(this.scheduleSelected.file, "imageContent", data)
    //         );
    //     }
    //   }
    //   return "";
    // }
  }
};
</script>