<template>
  <div class="container pt-0 pb-5">
    <div class="box-map-project">
      <div>
        <gmap-map
          ref="mapRef"
          :center="center"
          :options="options"
          :zoom="zoom"
          style="width:100%;  height: 400px;"
        >
          <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            @click="center=m.position"
          ></gmap-marker>
        </gmap-map>
      </div>
      <div class="legend">
        <h5 class="color-theme-texto">Abrangência</h5>
        <template v-for="coverage in coverageList">
          <a @click="pinFocus(coverage)" :key="coverage.id">
            <Icon :name="'fal fa-map-marker-alt'" :size="20" />
            {{formatCoverageName(coverage.name)}}
          </a>
        </template>
      </div>
    </div>
  </div>
</template>
<script type="plain/text">
import FileService from "@/scripts/services/file.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import { gmapApi } from "vue2-google-maps";
export default {
  props: ["coverageList"],
  data() {
    return {
      center: { lat: -14.235004, lng: -51.92527999999999 }, //Brasil
      zoom: 4,
      markers: [],
      google: gmapApi,
      currentPlace: null,
      options: {
        gestureHandling: "none",
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5"
              }
            ]
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161"
              }
            ]
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#f5f5f5"
              }
            ]
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#bdbdbd"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#dadada"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161"
              }
            ]
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5"
              }
            ]
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#c9c9c9"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          }
        ]
      }
    };
  },
  mounted() {
    this.addMarkers();
  },
  // async mounted() {
  //   this.addMarkers();
  //   debugger;
  //   const googleMapApi = await GoogleMapsApiLoader({
  //     apiKey: 'AIzaSyDn53tMbvEe-c5EexIOneIZsYSFMd0U7Ls'
  //   })
  //   this.google = googleMapApi;
  //   this.fitMapBounds();
  // },
  // watch: {
  // 	"google.maps": {
  // 		handler: function(after, before) {
  //       debugger;
  // 			this.fitMapBounds();
  // 		},
  // 		deep: true
  // 	}
  // },
  // watch: {
  //   google: {
  //     immediate: true,
  //     handler(val, oldVal) {
  //       debugger;
  //       if(val.maps){
  //         debugger;
  //         this.fitMapBounds();
  //       }
  //     }
  //   }
  // },
  // computed: {
  //   LatLngBounds: function() {
  //     debugger;
  //     if (new google.maps) {
  //       this.fitMapBounds();
  //     }
  //   }
  // },
  // computed: {
  //   google() {
  //     debugger;
  //     if (gmapApi) console.log(gmapApi);
  //     return gmapApi;
  //   }
  // },
  methods: {
    addMarkers() {
      if (this.coverageList)
        for (let i = 0; i < this.coverageList.length; i++) {
          if (this.coverageList[i].lat && this.coverageList[i].long) {
            const marker = {
              lat: this.coverageList[i].lat,
              lng: this.coverageList[i].long
            };
            this.markers.push({ position: marker });
          }
        }

      this.fitMapBounds();
    },
    pinFocus(coverage) {
      this.center = {
        lat: coverage.lat,
        lng: coverage.long
      };
      this.zoom = 6;
    },
    fitMapBounds() {
      if (this.google && this.google.maps) {
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < this.markers.length; i++) {
          bounds.extend(this.markers[i].position);
        }
        this.$refs.mapRef.$mapPromise.then(map => {
          map.fitBounds(bounds);
        });
      }
    },
    formatCoverageName(name) {
      var result = name.split(",");
      if (result.length > 0) return result[0];
      return name;
    }
    // geolocate: function() {
    // navigator.geolocation.getCurrentPosition(position => {
    //   this.center = {
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude
    //   };
    // });
    // }
  }
};
</script>