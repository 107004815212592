4<template>
	<div class="my-5 py-5">
		<div class="mb-3">
			<h3 class="title-container font-destaque color-theme-texto f-size-32 container display-flex justify-center c-gray-dark mb-5">Sobre a instituição</h3>
		</div>
		<div class="container-fluid aux-cfluid px-4">
			<v-card color="basil" class="tabs-default">
				<!-- TABS ######################### -->
				<v-tabs v-model="tab" background-color="transparent" color="basil" grow>
					<v-tab class="color-theme-texto">
						<Icon :name="'fal fa-info'" :size="20" class="color-theme-padrao mr-3" />Sobre
					</v-tab>
					<v-tab class="color-theme-texto">
						<Icon :name="'fal fa-camera-retro'" :size="20" class="color-theme-padrao mr-3" />Galeria de fotos
					</v-tab>
					<v-tab v-if="showPartnerBlock" class="color-theme-texto">
						<Icon :name="'fal fa-users'" :size="20" class="color-theme-padrao mr-3" />Parceiros
					</v-tab>
					<v-tab v-if="showTestimonyBlock" class="color-theme-texto">
						<Icon :name="'fal fa-coments'" :size="20" class="color-theme-padrao mr-3" />Depoimentos
					</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab" class="sobre">
					<!-- ABOUT ######################### -->
					<v-tab-item>
						<v-card flat color="basil">
							<v-card-text>
								<div class="block-projetos display-flex pa-4">
									<div
										v-if="galleryList && galleryList.length > 0"
										class="img-tab"
										:style="'background-image:' + 'url(' + galleryList[0].file.imageContent + ')'"
									></div>
									<div
										v-else
										class="img-tab"
										:style="'background-image:' + 'url(' + institution.file.imageContent + ')'"
									></div>
									<div class="fl1 projeto-desc">
										<img :src="institution.file.imageContent" alt class="mb-3" :title="institution.entity.name"/>
										<h2 class="color-theme-primaria" v-html="institution.entity.name"></h2>
										<h3 class="color-theme-texto">Missão:</h3> 
										<span class="color-theme-texto" v-html="institution.mission"></span>
										<!-- <div class="link">
                        <i class="fal fa-globe"></i>
                        <a href="https://www.ibfl.org.br">www.ibfl.org.br</a>
                      </div>
                      <div class="display-flex medias">
                        <a
                          href="https://www.facebook.com/InstitutoBeatrizeLauroFiuza?fref=ts/"
                          class="fab fa-facebook-f"
                        ></a>
                        <a href="https://twitter.com/IBLFiuza/" class="fab fa-twitter"></a>
                        <a
                          href="https://www.youtube.com/channel/UC2S9xyfePLZHYUMwORVY0JQ/"
                          class="fab fa-youtube"
                        ></a>
                        <a
                          href="https://www.instagram.com/institutobeatrizelaurofiuza/"
                          class="fab fa-instagram"
                        ></a>
										</div>-->
									</div>
									<!-- <div>
                    <ul class="lista">
                      <li
                        class="display-flex justify-content-between align-items-center bg-primary"
                      >
                        <span>
                          <i class="fal fa-clipboard-list-check"></i>
                          Auditoria Externa
                        </span>
                        <i class="far fa-check"></i>
                      </li>
                      <li
                        class="display-flex justify-content-between align-items-center bg-primary"
                      >
                        <span>
                          <i class="fal fa-clipboard-list-check"></i>
                          Utilidade Pública
                        </span>
                        <i class="fal fa-handshake"></i>
                      </li>
                      <li
                        class="display-flex justify-content-between align-items-center bg-primary"
                      >
                        <span>
                          <i class="fal fa-badge-percent"></i>
                          100% gratuito
                        </span>
                        <i class="far fa-check"></i>
                      </li>
                    </ul>
									</div>-->
								</div>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<!-- GALLERY ######################### -->
					<vue-easy-lightbox
						:visible="galleryLightboxVisible"
						:imgs="getGalleryLightboxImgs()"
						:index="galleryLightboxIndex"
						@hide="galleryLightboxVisible = false"
					></vue-easy-lightbox>
					<v-tab-item>
						<v-card flat color="basil">
							<v-card-text>
								<div class="section image-grid wrapper-scroll-father">
									<a
										href="javascript:;"
										onclick="document.getElementById('galleryAboutWrapper').scrollBy(415, 0)"
										class="scroll-arrow next"
									>
										<Icon name="far fa-chevron-right" size="16" />
									</a>
									<a
										href="javascript:;"
										onclick="document.getElementById('galleryAboutWrapper').scrollBy(-415, 0)"
										class="scroll-arrow prev"
									>
										<Icon name="far fa-chevron-left" size="16" />
									</a>
									<div class="p-relative wrapper-scroll" id="galleryAboutWrapper">
										<!-- <slick :options="slickOptionsGallery" ref="gallerySlick" class="bg-white"> -->
											<div class="item gallery-about-box" v-for="(image, index) in galleryList" :key="image.id">
												<div class="tools">
													<a class="fal fa-search-plus" @click="openGalleryLightbox(index)"></a>
												</div>
												<FileImageInstitution :fileId="image.file.id" :fileInstitution="image" :alt="''" />
											</div>
										<!-- </slick> -->
									</div>
								</div>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<!-- PARTNERS ######################### -->
					<v-tab-item v-if="showPartnerBlock">
						<v-card flat color="basil">
							<v-card-text>
								<div class="apoiadores w-100">
									<div class="container-slick">										
										<div class="section image-grid wrapper-scroll-father">
											<a
												href="javascript:;"
												onclick="document.getElementById('partnerAboutWrapper').scrollBy(377, 0)"
												class="scroll-arrow next"
											>
												<Icon name="far fa-chevron-right" size="16" />
											</a>
											<a
												href="javascript:;"
												onclick="document.getElementById('partnerAboutWrapper').scrollBy(-377, 0)"
												class="scroll-arrow prev"
											>
												<Icon name="far fa-chevron-left" size="16" />
											</a>
											<div class="p-relative wrapper-scroll father-gallery-partners" id="partnerAboutWrapper">
											<!-- <slick :options="slickOptionsPartners" ref="partnersSlick" class="mb-0 w-100"> -->
												<template v-for="partner in institution.partnerList">
													<!-- <div
														class="image"
														:style="'background-image:' + 'url(' + partner.file.imageContent + ')'"
														:key="partner.id"
													></div> -->
													<div :key="partner.id" class="image">
														<FileImage 
														:file="partner.file"/>
													</div>
												</template>
												<!-- <div v-for="i in 7" :key="i" class="w-100">
													<img style="max-width:200px" src="https://images.pexels.com/photos/943626/pexels-photo-943626.jpeg" alt="">
												</div> -->
											<!-- </slick> -->
											</div>
										</div>
									</div>
								</div>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<!-- TESTIMONY ######################### -->
					<v-tab-item v-if="showTestimonyBlock">
						<v-card flat color="basil">
							<v-card-text>
								<div class="section image-grid wrapper-scroll-father">
									<a
										href="javascript:;"
										onclick="document.getElementById('testimonyAboutWrapper').scrollBy(310, 0)"
										class="scroll-arrow next color-theme-texto"
									>
										<Icon name="far fa-chevron-right" size="16" />
									</a>
									<a
										href="javascript:;"
										onclick="document.getElementById('testimonyAboutWrapper').scrollBy(-310, 0)"
										class="scroll-arrow prev"
									>
										<Icon name="far fa-chevron-left color-theme-texto" size="16" />
									</a>
									<div class="p-relative wrapper-scroll" id="testimonyAboutWrapper">
										<!-- <v-carousel :show-arrows="true" hide-controls :cycle="false" height="100%"> -->
										<!-- <v-carousel-item v-for="n in Math.ceil(testimonyList.length / 3)" :key="n"> -->
										<!-- <template v-for="testimony in testimonyList.slice(3 * (n - 1), 3 * n)"> -->
										<!-- <slick :options="slickOptionsTestimony" ref="testimonySlick" class="w-100 mb-0"> -->
											<template v-for="testimony in testimonyList">
												<div class="depoimentos" :key="testimony.id">
													<div class="depo-block">
														<FileImage 
														class="depo" 
														:file="testimony.file" 
														effects="c_crop,g_face:auto,h_300,w_300,dpr_2.0"
														/>
														<h4 class="color-theme-texto">{{testimony.position}}</h4>
														<h5 class="color-theme-texto">{{testimony.name}}</h5>
														<p class="color-theme-texto">{{testimony.text}}</p>
													</div>
												</div>
											</template>
										<!-- </slick> -->
										<!-- </v-carousel-item> -->
										<!-- </v-carousel> -->
									</div>
								</div>
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-card>
		</div>
	</div>
</template>
<script type="plain/text">
import FileImageInstitution from "@/components/FileImageInstitution.vue";
import FileService from "@/scripts/services/file.service";
import TestimonyInstitutionService from "@/scripts/services/testimonyInstitution.service";
import ImageInstitutionService from "@/scripts/services/imageInstitution.service.js";
import FileImage from "@/components/FileImage.vue";
export default {
	components: {
		FileImageInstitution,
		FileImage
	},
	props: ["institution"],
	data() {
		return {
			tab: null,
			testimonyList: [],
			galleryList: [],
			galleryLightboxIndex: 0,
			galleryLightboxVisible: false,
			showTestimonyBlock: true,
			showPartnerBlock: true,
			fileService: new FileService(),
			testimonyInstitutionService: new TestimonyInstitutionService(),
			imageInstitutionService: new ImageInstitutionService(),
			slickOptionsTestimony: {
				dots: false,
				infinite: true,
				speed: 1000,
				slidesToShow: 3,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 7000,
				arrows: true,
				responsive: [
					{
						breakpoint: 1300,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1100,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 730,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			},
			slickOptionsPartners: {
				dots: false,
				infinite: true,
				speed: 1000,
				slidesToShow: 3,
				slidesToScroll: 1,
				autoplay: false,
				autoplaySpeed: 7000,
				arrows: true,
				responsive: [
					{
						breakpoint: 1300,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1100,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 730,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			},
			slickOptionsGallery: {
				dots: false,
				infinite: true,
				speed: 1000,
				slidesToShow: 3,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 7000,
				arrows: true,
				responsive: [
					{
						breakpoint: 1300,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1100,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 730,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			}
		};
	},
	created(){
		this.listTestimonyByInstitutionId();
		this.getPartnerLogos();
	},
	watch: {
		tab(val) {
			if (val == 0 || val == 1) this.getGalleryByInstitutionId();
			if (val == 2) this.getPartnerLogos();
			if (val == 3) this.listTestimonyByInstitutionId();
		},
		galleryList: {
			handler: function(after, before) {
				this.reInitSlick(this.$refs.gallerySlick);
			},
			deep: true
		},
		"institution.partnerList": {
			handler: function(after, before) {
				this.reInitSlick(this.$refs.partnersSlick);
			},
			deep: true
		},
		testimonyList: {
			handler: function(after, before) {
				this.reInitSlick(this.$refs.testimonySlick);
			},
			deep: true
		}
	},
	methods: {
		reInitSlick(slickComponent, gotoCurrentPage = false) {
			if (slickComponent) {
				let currIndex = 0;
				if (gotoCurrentPage) currIndex = slickComponent.currentSlide();
				slickComponent.destroy();
				this.$nextTick(() => {
					slickComponent.create();
					if (gotoCurrentPage) slickComponent.goTo(currIndex, true);
				});
			}
		},
		// tab 1 - Gallery
		getGalleryByInstitutionId() {
			if (this.galleryList.length == 0) {
				this.imageInstitutionService.listByInstitutionId(
					this.getGalleryCallback,
					this.institution.id
				);
			}
		},
		getGalleryCallback(data) {
			this.galleryList = data;
		},
		openGalleryLightbox(index) {
			this.galleryLightboxVisible = true;
			this.galleryLightboxIndex = index;
		},
		getGalleryLightboxImgs() {
			let imgs = [];
			if (this.galleryList == null) return [];
			for (let i = 0; i < this.galleryList.length; i++) {
				if (
					this.galleryList[i] != null &&
					this.galleryList[i].file != null
				)
					imgs.push(this.galleryList[i].file.path);
			}
			return imgs;
		},
		// tab 2 - Partners
		getPartnerLogos() {
			if (
				this.institution.partnerList &&
				this.institution.partnerList.length > 0
			) {
				this.showPartnerBlock = true;
				var partnerList = this.institution.partnerList;
				for (let i = 0; i < partnerList.length; i++) {
					if (partnerList[i].file.hasOwnProperty("imageContent")) {
						return partnerList[i].file.imageContent;
					} else {
						this.fileService
							.render(partnerList[i].file.id)
							.then(data =>
								this.$set(
									partnerList[i].file,
									"imageContent",
									data
								)
							);
					}
				}
			}
			else{
				this.showPartnerBlock = false;
			}
		},
		// tab 3 - Testimony
		listTestimonyByInstitutionId() {
			if (this.testimonyList.length == 0) {
				this.testimonyInstitutionService.listByInstitutionId(
					this.listTestimonyCallback,
					this.institution.id
				);
			}
		},
		listTestimonyCallback(data) {
			this.testimonyList = data;
			if(this.testimonyList.length > 0){
				this.showTestimonyBlock = true;
				this.getTestimonyProfile();
			}
			else
				this.showTestimonyBlock = false;
		},
		getTestimonyProfile() {
			if (this.testimonyList && this.testimonyList.length > 0) {
				for (let i = 0; i < this.testimonyList.length; i++) {
					if (
						this.testimonyList[i].file.hasOwnProperty(
							"imageContent"
						)
					) {
						return this.testimonyList[i].file.imageContent;
					} else {
						this.fileService
							.render(this.testimonyList[i].file.id)
							.then(data =>
								this.$set(
									this.testimonyList[i].file,
									"imageContent",
									data
								)
							);
					}
				}
			}
		}
	}
};
</script>