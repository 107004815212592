<template>
  <section v-if="minibio" key="minibio" class="bg-theme-white" data-title="Minibio" ref="minibioBlock">
    <div class="container">
      <div class="minibio ql-editor" pellcheck="false" data-title="Minibio" v-html="minibio"></div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    minibio: {
      type: String,
      default: null
    }
  },
}
</script>
<style scoped>
.minibio {
  font-size: 16px;
  line-height: 1.5em;
  text-align: left;
}

p {
  margin-bottom: 12px;
}

</style>