<template>
  <div>
    <div>
      <h3
        class="title-container container display-flex justify-center c-gray-dark mb-3 f-size-32 font-destaque color-theme-texto"
      >Conheça os apoiadores do projeto</h3>
    </div>
    <div class="apoiadores">
      <div class="container">
        <v-layout row wrap wrapper-supportersview>
          <!-- <slick :options="slickOptionsSupporter" class="slider"> -->
            <template v-for="supporter in supporterList">
                <FileImage :key="supporter.id" :useBg="true" :file="supporter.file" class="image" />
            </template>
            <!-- <div
              v-for="supporter in supporterList"
                class="image"
                :style="'background-image:' + 'url(' + supporter.file.imageContent + ')'"
                :key="supporter.id"
            ></div>-->
          <!-- </slick> -->
        </v-layout>
      </div>
    </div>
  </div>
</template>
<script type="plain/text">
import FileService from "@/scripts/services/file.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import FileImage from "@/components/FileImage.vue";
export default {
  components: {
    FileImage
  },
  props: ["supporterList"],
  data() {
    return {
      fileService: new FileService(),
      slickOptionsSupporter: {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 730,
            settings: {
              // arrows: false,
              slidesToShow: 1,
              arrows: false,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  // watch: {
  //   supporterList: {
  //     immediate: true,
  //     handler(val, oldVal) {
  //       this.getSupporterLogos();
  //     }
  //   }
  // },
  // methods: {
  //   getSupporterLogos() {
  //     if (this.supporterList && this.supporterList.length > 0) {
  //       for (let i = 0; i < this.supporterList.length; i++) {
  //         if (this.supporterList[i].file.hasOwnProperty("imageContent")) {
  //           return this.supporterList[i].file.imageContent;
  //         } else {
  //           this.fileService
  //             .render(this.supporterList[i].file.id)
  //             .then(data =>
  //               this.$set(this.supporterList[i].file, "imageContent", data)
  //             );
  //         }
  //       }
  //     }
  //   }
  // }
};
</script>