<template>
  <div>
    <!-- #modal filtros  -->
    <Modal
      :modalTitle="'Avaliação ' + projectName"
      :cardTitle="true"
      :withClose="true"
      myMaxWidth="640px"
      :modalFooter="true"
      v-model="dialog"
      @input="input"
    >
      <!-- body  -->
      <template>
        <v-container fluid grid-list-sm pa-3>
          <v-layout align-center wrap>
            <v-flex xs12>
              <InputSelect
                v-model="evaluation"
                :textLabel="'Qual é a sua avaliação para o projeto ' + projectName + '?'"
                valueAttribute="id"
                textAttribute="name"
                unselectedValue="null"
                unselectedText="Avalie o projeto de 1 a 5"
                :items="items"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </template>

      <!-- action buttons  -->
      <template v-slot:footer>
        <v-container fluid>
          <v-layout>
            <v-flex text-xs-right>
              <v-btn flat round color="white" class="btn-default px-4" large @click="close">Cancelar</v-btn>
              <v-btn flat color="white" class="btn-primary px-4" large @click="evaluate">Avaliar</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </Modal>
  </div>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
export default {
  props: ["value", "projectName"],
  components: {
    Modal
  },
  data() {
    return {
      dialog: false,
      evaluation: null,
      items: [
        { id: 1, name: "1 estrela" },
        { id: 2, name: "2 estrelas" },
        { id: 3, name: "3 estrelas" },
        { id: 4, name: "4 estrelas" },
        { id: 5, name: "5 estrelas" }
      ]
    };
  },
  watch: {
    value() {
      this.dialog = this.value;
    }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.$emit("close");
      this.dialog = false;
    },
    input() {
      if (!this.dialog) this.$emit("close");
    },
    evaluate() {
      this.$emit("evaluate", this.evaluation);
      this.dialog = false;
    }
  }
};
</script>
