<template>
  <section v-if="videoId" class="section-video">
    <iframe width="100%" height="100%" :src="'https://www.youtube.com/embed/' + videoId" frameborder="0"
      allowfullscreen></iframe>
  </section>
</template>
<script>
export default {
  props: {
    videoUrl: {
      type: String,
      default: null,
    },
  },
  computed: {
    videoId() {
      if (!this.videoUrl) return null;
      return this.videoUrl.split("v=")[1];
    },
  },
}
</script>
<style scoped>
.section-video {
  height: 60vh;
  width: 100%;
}
</style>