<template>
  <div class="mt-5 pt-4">
    <v-flex display-flex flex-column mb-3 align-center justify-center>
      <h3 class="title-container mb-5 font-destaque f-size-32 color-theme-texto">Público Alvo</h3>
    </v-flex>
    <div class="container-fluid px-4 aux-cfluid">
      <v-layout row wrap justify-center>
        <v-flex md4 display-flex flex-column pr-3 w-680-100 p-680-r-0>
          <div class="card-container display-flex mb-2">
            <div class="display-flex flex-column">
              <div class="title-card py-4 b-right-2 color-theme-texto">Beneficiários Diretos</div>
              <div
                class="valor-card lg py-4 b-right-2 color-theme-primaria"
              >{{formatterHelper.formatShortNumber(projectLP.project.directBeneficiaries, 0)}}</div>
              <strong class="c-text-dark">{{projectLP.project.directBeneficiariesDescription}}</strong>
            </div>
            <div class="display-flex flex-column">
              <div class="title-card py-4 color-theme-texto">Beneficiários Indiretos</div>
              <div
                class="valor-card lg py-4 color-theme-primaria"
              >{{formatterHelper.formatShortNumber(projectLP.project.indirectBeneficiaries, 0)}}</div>
            </div>
          </div>
          <div class="card-container fl1" v-if="projectLP.project.ageRangeList.length > 0">
            <div class="display-flex flex-column h100">
              <div class="title-card py-4 color-theme-texto">Divisão por Gênero</div>
              <div class="display-flex h100">
                <div class="display-flex flex-column fl1">
                  <div
                    class="b-right-2 display-flex flex-column gray pa-2 h100 justify-content-center p-relative"
                  >
                    <div
                      class="bg-percent"
                      :style="{height: 'calc(' + projectLP.beneficiariesDistributionMale + '% - 16px)'}"
                    ></div>
                    <img :src="require('@/assets/images/men/19-59.svg')" alt />
                    <span class="py-2 genre-card color-theme-texto">Homens</span>
                    <strong
                      class="valor-card color-theme-primaria"
                    >{{ projectLP.beneficiariesDistributionMale }}%</strong>
                  </div>
                </div>
                <div class="display-flex flex-column fl1">
                  <div
                    class="display-flex flex-column gray pa-2 h100 justify-content-center p-relative"
                  >
                    <div
                      class="bg-percent"
                      :style="{height: 'calc(' + projectLP.beneficiariesDistributionFemale + '% - 16px)'}"
                    ></div>
                    <img :src="require('@/assets/images/women/19-59.svg')" alt />
                    <span class="py-2 genre-card color-theme-texto">Mulheres</span>
                    <strong
                      class="valor-card color-theme-primaria"
                    >{{ projectLP.beneficiariesDistributionFemale }}%</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-flex>
        <v-flex md7 v-if="projectLP.project.ageRangeList.length > 0">
          <div class="card-container flex-column">
            <div class="duo text-center title-card py-4">Beneficiados por faixa etária</div>
            <div class="duo display-flex flex-column px-4">
              <template
                v-for="(age, index) in projectLP.beneficiariesDistributionByAgeClassification"
              >
                <div class="display-flex align-center justify-center my-4 face-svg" :key="index">
                  <img :src="ageRangeItems[index].img" alt />
                  <div class="display-flex flex-column progress-full mb-3">
                    <div class="info">{{ageRangeItems[index].name}}</div>
                    <div class="display-flex align-items-center">
                      <div class="progress large mr-3">
                        <div
							class="bg-theme-primaria"
                          :style="{width:age.item2+'%'}"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="count-progress">
                    {{formatterHelper.formatShortNumber(age.item1, 0)}}
                    <span class="color-theme-destaque">{{age.item2}}%</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script type="plain/text">
import FileService from "@/scripts/services/file.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
export default {
  props: ["projectLP", "colorPrimary", "colorSecondary"],
  data() {
    return {
      formatterHelper: new FormatterHelper(),
      ageRangeItems: [
        {
          name: "0 - 12 anos",
          img: require("@/assets/images/men/0-12.svg")
        },
        {
          name: "13 - 18 anos",
          img: require("@/assets/images/men/13-18.svg")
        },
        {
          name: "19 - 59 anos",
          img: require("@/assets/images/men/19-59.svg")
        },
        {
          name: "60 anos ou +",
          img: require("@/assets/images/men/60.svg")
        }
      ]
    };
  }
};
</script>