<template>
  <div class="donation-progress mt-5" id="donation-progress">
    <div
      class="bar-fill"
      :style="{background: 'linear-gradient(225deg, '+colorPrimary+' 0%, '+colorSecondary+' 100%)',
			  width:calculatePercentual(project.totalDonatedValueWithCapturedValue, project.totalValue, 5, 100)}"
    >
      <h2
        class="d-block d-sm-none"
        data-container="#donation-progress"
        data-toggle="tooltip"
        data-html="true"
        style="margin-right: initial;color:white"
        title
        data-placement="bottom"
      >R$ {{ formatterHelper.formatMoney(project.totalDonatedValueWithCapturedValue) }}</h2>
      <!-- mobile -->
      <h2
        class="d-sm-block d-none"
        data-container="#donation-progress"
        data-toggle="tooltip"
        data-html="true"
        style="margin-right: initial;color:white"
        title
        data-placement=" bottom"
      >R$ {{ formatterHelper.formatShortNumber(project.totalDonatedValueWithCapturedValue, 1) }}</h2>
    </div>
    <h2 class="d-block d-sm-none color-theme-primaria">R$ {{ formatterHelper.formatMoney(project.totalValue) }}</h2>
    <h2
      class="d-sm-block d-none"
    >R$ {{ formatterHelper.formatShortNumber(project.totalValue, 1) }}</h2>
  </div>
</template>
<script type="plain/text">
import FormatterHelper from "@/scripts/helpers/formatter.helper";
export default {
  props: ["project", "colorPrimary", "colorSecondary"],
  data() {
    return {
      formatterHelper: new FormatterHelper()
    };
  },
  methods: {
    calculatePercentual(current, total, minimum = 0, maximum = 100) {
      let v = 0;
      if (total > 0 && current > 0) {
        v = Math.round((current / total) * 100);
        if (v < 100) v = Math.min(maximum, Math.max(minimum, v));
      }
      return v + "%";
    }
  }
};
</script>