<template>
  <div>
    <div>
      <h3 class="title-container container display-flex justify-center c-gray-dark">Metas</h3>
    </div>
    <!-- <v-carousel
          hide-controls
          height="100%"
          class="ball-cases"
          v-if="!campaignLandingPageConfig.campaignCaseList || campaignLandingPageConfig.campaignCaseList.length == 0"
          interval="10000"
        >
          <v-carousel-item
            v-for="i in 3"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <v-layout wrap fill-height align-center>
              <v-flex fill-height md6 xs12 bg-theme-fundo h-960-initial>
                <div class="img-container icon-white"></div>
              </v-flex>
              <v-flex md6 xs12 pa-5 display-flex align-center>
                <div class="text-block">
                  <h2>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia</h2>
                  <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind of shining texts</p>
                </div>
              </v-flex>
            </v-layout>
          </v-carousel-item>
    </v-carousel>-->
    <div class="container">
      <v-layout row wrap justify-center>
        <template v-for="goal in goalList">
          <v-flex class="md3 pr-3" :key="goal.id">
            <div class="card-container flex-column">
              <div class="duo text-center title-card py-4">{{goal.name}}</div>
              <div class="display-flex">
                <div class="display-flex flex-column fl1">
                  <div
                    class="display-flex flex-column gray pa-2 h100 justify-content-center p-relative"
                  >
                    <div class="bg-percent"></div>
                    <strong class="valor-card" :style="{color: colorPrimary}">{{goal.amount}}</strong>
                    <span class="py-2">
                      <strong class="c-text-dark">{{goal.goalType.name}}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </template>

        <!-- <v-flex class="md3 pr-3">
                <div class="card-container flex-column">
                  <div class="duo text-center title-card py-4">Nº de atendimentos psicossociais</div>
                  <div class="display-flex" style="min-height: 220px">
                    <div class="display-flex flex-column fl1">
                      <div
                        class="display-flex flex-column gray pa-2 h100 justify-content-center p-relative"
                      >
                        <div class="bg-percent" style="height: calc(100% - 16px);"></div>
                        <span class="py-2">
                          <strong class="c-text-dark">180 pessoas</strong>
                        </span>
                        <strong class="valor-card" :style="{color: color_primary}">100%</strong>
                      </div>
                    </div>
                  </div>
                </div>
        </v-flex>-->
        <!-- <v-flex class="md3 pr-3">
                <div class="card-container flex-column">
                  <div class="duo text-center title-card py-4">Encontros com as famílias</div>
                  <div class="display-flex" style="min-height: 220px">
                    <div class="display-flex flex-column fl1">
                      <div
                        class="display-flex flex-column gray pa-2 h100 justify-content-center p-relative"
                      >
                        <div class="bg-percent" style="height: calc(60% - 16px);"></div>
                        <span class="py-2">
                          <strong class="c-text-dark">10 famílias</strong>
                        </span>
                        <strong class="valor-card" :style="{color: color_primary}">60%</strong>
                      </div>
                    </div>
                  </div>
                </div>
        </v-flex>-->
        <!-- <v-flex class="md3">
                <div class="card-container flex-column">
                  <div class="duo text-center title-card py-4">Horas de capacitação da equipe</div>
                  <div class="display-flex" style="min-height: 220px">
                    <div class="display-flex flex-column fl1">
                      <div
                        class="display-flex flex-column gray pa-2 h100 justify-content-center p-relative"
                      >
                        <div class="bg-percent" style="height: calc(50% - 16px);"></div>
                        <span class="py-2">
                          <strong class="c-text-dark">12h</strong>
                        </span>
                        <strong class="valor-card" :style="{color: color_primary}">50%</strong>
                      </div>
                    </div>
        </div>-->
        <!-- <div class="duo display-flex flex-column">
                                		<div class="chart4"></div>
        </div>-->
        <!-- </div>
        </v-flex>-->
      </v-layout>
    </div>
  </div>
</template>
<script type="plain/text">
import FileService from "@/scripts/services/file.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
export default {
  props: ["goalList", "colorPrimary"]
};
</script>