<template>
  <div>
    <div>
      <h3 class="title-container container display-flex justify-center c-gray-dark mt-5 color-theme-texto font-destaque f-size-32">Notícias</h3>
    </div>
    <div class="container display-flex justify-content-between flex-wrap">
      <div class="slicks display-flex justify-center align-center">
        <slick :options="slickOptionsNews" ref="newsslick">
          <template v-for="news in newsList">
            <div class="noticia project" :key="news.id">
              <div class="image" :style="'background-image:' + 'url(' + news.file.imageContent + ')'"></div>
              <!-- <h5 :style="{color: color_alternative}">07/02/2019</h5> -->
              <h4 class="color-theme-primaria">{{news.title}}</h4>
              <span class="text-news-lp color-theme-texto" v-html="news.body"></span>
              <a
                href="https://www.iblf.org.br/como-atuamos/programas/programa-de-musica-jacques-klein"
              >
                <!-- <h5 :style="{color: color_alternative}">leia mais</h5> -->
              </a>
            </div>
          </template>
        </slick>
        <!-- <div class="noticia">
          <div
            class="image"
            style="background-image: url(https://images.pexels.com/photos/1322611/pexels-photo-1322611.jpeg)"
          ></div>
          <h5 :style="{color: color_alternative}">07/02/2019</h5>
          <h4>Programa de Karatê Bushi No Te</h4>
          <p>
            Mãos de Guerreiro. Eis a simbologia traduzida no próprio nome do Programa de Karatê Bushi No Te
            – BSNT, que tem como
            missão associar a prática das artes marciais a valores como autonomia, inclusão social e
            cidadania. Entendendo o papel
            do Karatê como método e doutrina voltados ao desenvolvimento humano e ao aperfeiçoamento do
            caráter, o programa exerce
            forte influência sobre os educandos, atrelando à prática esportiva uma formação para a
            cidadania capaz de apontar novas
            perspectivas de vida no contexto d...
          </p>
          <a href="https://www.iblf.org.br/como-atuamos/programas/programa-de-karate-bushi-no-te">
            <h5 :style="{color: color_alternative}">leia mais</h5>
          </a>
        </div>-->
        <!-- <div class="noticia">
          <div
            class="image"
            style="background-image: url(https://images.pexels.com/photos/1068205/pexels-photo-1068205.jpeg)"
          ></div>
          <h5 :style="{color: color_alternative}">07/02/2019</h5>
          <h4>Programa Envolver de Desenvolvimento Humano</h4>
          <p>
            O Programa Envolver dá apoio aos 600 alunos do instituto e às suas famílias, proporcionando
            atividades coletivas
            regulares e atendimentos para o encaminhamento de demandas psico-pedagógico-sociais.
            As crianças e adolescentes atendidos são, em sua maioria, de famílias de baixa renda e baixa
            escolaridade, inseridas em
            bairros com índices preocupantes de violência, vulnerabilidade e risco social. Considerando
            esse contexto social, o ...
          </p>
          <a href="https://www.iblf.org.br/como-atuamos/programas/programa-de-acompanhamento-social">
            <h5 :style="{color: color_alternative}">leia mais</h5>
          </a>
        </div>-->
      </div>
    </div>
  </div>
</template>
<script type="plain/text">
import FileService from "@/scripts/services/file.service";
export default {
  props: ["newsList"],
  data() {
    return {
      fileService: new FileService(),
      color_alternative: "#fa6e6e",
      slickOptionsNews: {
				dots: false,
				infinite: true,
				speed: 1000,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 7000,
        arrows: true,
				responsive: [
					{
						breakpoint: 1600,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1300,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1100,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 730,
						settings: {
							// arrows: false,
							slidesToShow: 1,
							arrows: false,
							slidesToScroll: 1
						}
					}
				]
			}
    };
  },
  watch: {
    newsList: {
      immediate: true,
      handler(val, oldVal) {
        this.getBanners();
      }
    }
  },
  methods: {
    getBanners() {
      if (this.newsList && this.newsList.length > 0) {
        for (let i = 0; i < this.newsList.length; i++) {
          if (this.newsList[i].file.hasOwnProperty("imageContent")) {
            return this.newsList[i].file.imageContent;
          } else {
            this.fileService
              .render(this.newsList[i].file.id)
              .then(data =>
                this.$set(this.newsList[i].file, "imageContent", data)
              );
          }
        }
      }
    }
  }
};
</script>