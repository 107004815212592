<template>
  <div class="p-relative">
    <div class="gradient">
      <div class="skew bg-theme-gradiente-principal"></div>
      <div class="skew bg-theme-gradiente-principal"></div>
	  <div class="skew bg-theme-gradiente-principal"></div>
	  <div class="skew bg-theme-gradiente-principal"></div>
      <span class="text p-absolute">
        <div class="container">
          <v-layout row>
            <v-flex md12 ma-auto display-flex py-4>
              <div class="content text-xs-center mx-auto">
                <div align="center">
                  <h2
                    :contenteditable="editing"
                    spellcheck="false"
                    data-title="Título"
                    class="text-xs-center font-destaque text-white"
                    @blur="updateProperty('simulationTitle', $event)"
                    v-html="projectLP.simulationTitle ? projectLP.simulationTitle : projectLangingPage.simulationTitle"
                  ></h2>
                </div>
                <p
					style="max-width:740px"
                  :contenteditable="editing"
                  spellcheck="false"
				  class="text-xs-center mt-3"
                  data-title="Parágrafo"
                  @blur="updateProperty('simulationParagraph', $event)"
                  v-html="projectLP.simulationParagraph ? projectLP.simulationParagraph : projectLangingPage.simulationParagraph"
                ></p>
                <!-- <h2>Seja um realizador de sonhos</h2>
                <h4>Ajude a manter vivo os sonhos de crianças, jovens e idosos!</h4>
                <p>
                  Todos os projetos são cuidadosamente selecionados em
                  parceria com grandes empresas e possuem excelência em suasd
                  áreas de atuação.
                  <br />
                  <br />Conheça os projetos apoiados pela
                  <strong>Abrace uma Causa</strong> e selecione aquele com o
                  qual você mais se identificar.
                  <br />
                  <br />Em poucos cliques você faz a sua doação para o projeto
                  selecionado com a segurança de que a sua doação será bem
                  utilizada. Você também conta com o nosso suporte para
                  facilitar a declaração de suas doações, podendo abater o
                  valor em seu imposto de renda.
                </p>-->
                <!-- @click.stop="landingPageOptions.simulationDrawer = !landingPageOptions.simulationDrawer" -->
                <button
                  class="btn-white mt-4 color-theme-primaria"
                  @click.stop="toggleSimulation"
                  v-if="ProjectTypes.ProjetoIncentivado == projectLP.project.projectTypeId"
                >faça uma simulação</button>
                <button class="btn-default" @click.stop="toggleSimulation" v-else>doar</button>
              </div>
            </v-flex>
          </v-layout>
        </div>
      </span>
    </div>
  </div>
</template>
<script type="plain/text">
import FileService from "@/scripts/services/file.service";
import ProjectService from "@/scripts/services/project.service";
import ProjectLandingPageConfig from "@/scripts/models/projectLandingPageConfig.model";
import { ProjectTypes } from "@/scripts/models/enums/projectTypes.enum.js";
export default {
  props: ["projectLP", "editing", "colorPrimary", "colorSecondary"],
  data() {
    return {
      fileService: new FileService(),
      projectService: new ProjectService(),
      projectLangingPage: new ProjectLandingPageConfig(),
      saving: false,
      saveQueue: [],
      ProjectTypes: ProjectTypes
    };
  },
  methods: {
    updateProperty(property, event) {
      if (this.projectLP[property] != event.target.innerHTML) {
        this.projectLP[property] = event.target.innerHTML;
        this.save();
      }
    },
    toggleSimulation() {
      this.$emit("toggleSimulation");
    },
    save() {
      if (!this.saving && this.saveQueue.length == 0) {
        this.saving = true;
        let sendObj = JSON.parse(JSON.stringify(this.projectLP));
        sendObj.project = null;
        this.projectService
          .saveLandingPageConfig(sendObj)
          .then(this.projectService_saveLandingPageConfigCallback)
          .catch(this.projectService_saveLandingPageConfigError);
      } else {
        this.saveQueue.push(this.projectLP);
      }
    },
    projectService_saveLandingPageConfigCallback(data) {
      this.projectLP.id = data.id;
      if (this.saveQueue.length != 0) {
        let plpc = this.saveQueue.shift();
        while (this.saveQueue.length > 0) plpc = this.saveQueue.shift();
        plpc = JSON.parse(JSON.stringify(data));
        plpc.project = null;
        this.projectService
          .saveLandingPageConfig(plpc)
          .then(this.projectService_saveLandingPageConfigCallback)
          .catch(this.projectService_saveLandingPageConfigError);
      } else {
        this.saving = false;
      }
    },
    projectService_saveLandingPageConfigError(error) {
      console.log(error);
      this.saveQueue = [];
      this.saving = false;
    }
  }
};
</script>
